import { getImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { Image } from "../Image"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")

interface Props {
  headline: string
  description: string
  transportationCardLinks: Array<{
    link: {
      target: string
      title: string
      url: string
    }
    icon: any
    description: string
    backgroundImage: any
  }>
}

const ParkingTransportation: React.FC<Props> = props => {
  const { headline, description, transportationCardLinks } = props
  const [text, setText] = useState({
    headline,
    description
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        headline,
        description
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          headline,
          description
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  
  return (
    <section css={[tw`mt-20 px-4 max-w-[1024px] mx-auto`]}>
      <SectionHeading textStyles={[tw`text-3xl md:text-4xl`]}>
        {text?.headline}
      </SectionHeading>
      <p css={[tw`hidden md:block mt-6 mx-auto text-center`]}>
        {text?.description}
      </p>
      <div
        css={[
          tw`grid grid-cols-1 md:grid-cols-2 gap-5 mx-auto mt-4 md:mt-10 lg:gap-7`,
        ]}
      >
        {transportationCardLinks?.map((item, i) => (
          <TransportationCard
            link={item.link}
            backgroundImage={item.backgroundImage}
            description={item.description}
            icon={item.icon}
            key={i}
          />
        ))}
      </div>
    </section>
  )
}

interface CardProps {
  link: {
    target: string
    title: string
    url: string
  }
  description: string
  backgroundImage: any
  icon: any
}

const TransportationCard: React.FC<CardProps> = props => {
  const { target, title, url } = props.link
  const { backgroundImage, description } = props
  const image = getImage(backgroundImage?.localFile)
  const icon = props?.icon?.localFile?.publicURL

  const [text, setText] = useState({
    title,
    description
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        title,
        description
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          description
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`mx-auto`]}>
      <div css={[tw`relative bg-[#202f45] hover:bg-[#007f7b]`]}>
      <Image
        alt=""
        src={image}
        publicURL={backgroundImage?.localFile?.publicURL}
        css={[tw`opacity-25 grayscale h-[13rem] w-full`]}
      />
        <div
          css={[
            tw`top-0 left-0 absolute w-full h-full flex flex-col justify-center items-center gap-2 p-4 text-center`,
          ]}
        >
          {icon && <Image alt={title} src={icon} css={tw`w-auto h-18`} />}
          <p css={[tw`text-white text-3xl`]}>{text?.title}</p>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: text?.description }} css={[tw`mt-5`]} />
    </div>
  )
}

export default ParkingTransportation
