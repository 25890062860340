import React from "react"
import ParkingRates from "../../components/ParkingTransportation/ParkingRates"
import ParkingSelfParking from "../../components/ParkingTransportation/ParkingSelfParking"
import tw from "twin.macro"
import ParkingValet from "../../components/ParkingTransportation/ParkingValet"
import ParkingTransportation from "../../components/ParkingTransportation/ParkingTransportation"
import ParkingCellPhone from "../../components/ParkingTransportation/ParkingCellPhone"
import ParkingFAQ from "../../components/ParkingTransportation/ParkingFAQ"
import { graphql, PageProps } from "gatsby"
import {
  ParkingAndGroundTransportationPageData,
  TemplateProps,
} from "../templates"
import { getImage } from "gatsby-plugin-image"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const ParkingPage: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<ParkingAndGroundTransportationPageData>) => {
  const { title, content, featuredImage, template } = data.wpPage
  const {
    parkingFaqSection,
    selfParkingSection,
    valetParkingSection,
    groundTransportationSection,
    cellPhoneLotSection,
  } = template.parkingAndTransportationPage

  const image = getImage(featuredImage?.node?.localFile)

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
      <Image
          src={image}
          publicURL={featuredImage?.node?.localFile?.publicURL}
          alt=""
          css={[tw`h-80`]}
          objectPosition="50% 100%"
          role="presentation"
        />
        <ParkingRates title={title} content={content} />
        <ParkingSelfParking {...selfParkingSection} />
        <ParkingValet {...valetParkingSection} />
        <ParkingTransportation {...groundTransportationSection} />
        <ParkingCellPhone {...cellPhoneLotSection} />
        <ParkingFAQ {...parkingFaqSection} />
      </section>
    </div>
  )
}

export default ParkingPage

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      ...PageData
      template {
        ... on WpTemplate_ParkingAndTransportation {
          templateName
          parkingAndTransportationPage {
            parkingFaqSection {
              faqs {
                answer
                question
              }
              fieldGroupName
              headline
            }
            cellPhoneLotSection {
              description
              headline
            }
            groundTransportationSection {
              headline
              description
              transportationCardLinks {
                link {
                  target
                  title
                  url
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
                description
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                    publicURL
                  }
                }
              }
            }
            selfParkingSection {
              description
              headline
              instruction
              parkingOptions {
                description
                maxHeightDetails
                locationMapIdentifier
                overview
                price {
                  hourlyRate
                  maxRate
                }
                title
              }
            }
            valetParkingSection {
              headline
              valetContent
              valetParkingCta {
                title
                url
              } 
            }
          }
        }
      }
    }
  }
`
