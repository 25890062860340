import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import SelfParkingCard from "./SelfParkingCard"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface Props {
  description: string
  headline: string
  instruction: string
  parkingOptions: Array<{
    description: string
    maxHeightDetails: string
    locationMapIdentifier: any
    overview: string
    price: {
      hourlyRate: number
      maxRate: number
    }
    title: string
  }>
}

const ParkingSelfParking: React.FC<Props> = ({
  headline,
  description,
  instruction,
  parkingOptions,
}) => {
  const [text, setText] = useState({
    headline,
    description,
    instruction,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description, instruction })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          headline,
          description,
          instruction,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`mt-24 px-6 mx-auto container 2xl:max-w-[1280px]`]}>
      <SectionHeading textStyles={[tw`text-3xl md:text-4xl`]}>
        {text?.headline}
      </SectionHeading>
      <div css={[tw`text-center mt-3`]}>{text?.description}</div>
      <div css={[tw`mt-8 border-t border-lightGray md:border-none md:grid md:grid-rows-[repeat(10, auto)] md:grid-flow-col xl:grid-rows-[repeat(5, auto)]`]}>
        {parkingOptions?.map((item, i) => (
          <SelfParkingCard key={item.title} parkingOptions={item} index={i} />
        ))}
      </div>
      <div
        css={[
          tw`pt-10 text-center md:border-0 md:mt-20 md:text-xl max-w-lg mx-auto`,
        ]}
      >
        {text?.instruction}
      </div>
    </article>
  )
}

export default ParkingSelfParking
