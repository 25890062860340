import React, { useState, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
interface Props {
  headline: string
  description: string
}

const ParkingCellPhone: React.FC<Props> = ({ headline, description }) => {
  const [text, setText] = useState({ headline, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-14 max-w-[1024px] mx-auto text-center`]}>
      <SectionHeading textStyles={[tw`text-3xl md:text-4xl`]}>
        {text?.headline}
      </SectionHeading>
      <p css={[tw`mt-6 px-4 lg:mt-12`]}>{text?.description}</p>
      {/* <div css={[tw`max-w-3xl mt-8 mx-4 md:mx-auto md:mt-12`]}>
        <CellPhoneMap />
      </div> */}
    </article>
  )
}

export default ParkingCellPhone

const CellPhoneMap = () => {
  const mapRef = useRef()

  useEffect(() => {
    const init = async () => {
      const jQuery = await import("jquery")
      await import("../../../static/jquery.mousewheel")
      await import("../../../static/magnific-popup.js")
      await import("../../../static/mapplic/mapplic")
      const map = jQuery.default(mapRef.current).mapplic({
        source: "/map-data.json",
        minimap: false,
        hovertip: true,
        maxscale: 3,
        search: false,
        sidebar: false,
        lightbox: false,
      })
      map.on("mapready", (e, self) => {
        self.switchLevel("lower")
      })
    }

    init()
  }, [])

  return (
    <div css={[tw`border border-lightGray`]}>
      <link rel="stylesheet" href="/mapplic/mapplic.css" />
      <div id="mapplic_map" ref={mapRef} />
    </div>
  )
}
