import { Interpolation, Theme } from "@emotion/react"
import React, { useRef, useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import Accordion from "../Accordion/Accordion"
interface Props {
  headline: string
  faqs: Array<{
    question: string
    answer: string
  }>
}

const ParkingFAQ: React.FC<Props> = ({ headline, faqs }) => {
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-14 md:mt-20 max-w-4xl mx-auto px-6 px-4 max-w-[1024px]`]}>
      <SectionHeading textStyles={[tw`text-3xl md:text-4xl`]}>
        {text?.headline}
      </SectionHeading>
      <div css={[tw`mt-7`]}>
        {faqs?.map((item, index) => (
          <ParkingFAQAccordionSlide
            index={index}
            title={item.question}
            key={index}
            answer={item.answer}
          />
        ))}
      </div>
    </article>
  )
}

export default ParkingFAQ

interface ParkingFAQAccordionSlideProps {
  title: string
  css?: Interpolation<Theme>
  index: number
  answer?: string
}

const ParkingFAQAccordionSlide: React.FC<ParkingFAQAccordionSlideProps> = ({ title, css, index, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef(null)
  const maxHeight = isExpanded ? contentRef?.current.scrollHeight : 0
  const marginBottom = isExpanded ? "1rem" : 0

  const toggleHandler = () => {
    setIsExpanded(prev => !prev)
  }

  const [text, setText] = useState({ answer })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ answer })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([answer])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <Accordion title={title} index={index}>
      <p css={tw`mb-4`} dangerouslySetInnerHTML={{__html: text?.answer}} />
    </Accordion>
  )
}
