import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { H3 } from "../Typography"
import Accordion from "../Accordion/Accordion"
import Button from "../Button/Button"

interface Props {
  parkingOptions: {
    title: string
    description: string
    price: {
      hourlyRate: number
      maxRate: number
    }
    locationMapIdentifier?: string
    overview: string
    maxHeightDetails: string
  }
  index: number
}

const SelfParkingCard: React.FC<Props> = ({parkingOptions, index}) => {
  const {
    title,
    description,
    price,
    locationMapIdentifier,
    maxHeightDetails,
    overview,
  } = parkingOptions

  const [isMobile, setIsMobile] = useState(null)

  const hourText = "hour"
  const dayText = "day"
  const viewMapText = "View Map"
  const [text, setText] = useState({
    title,
    description,
    overview,
    maxHeightDetails,
    hourText,
    dayText,
    viewMapText
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        title,
        description,
        overview,
        maxHeightDetails,
        hourText,
        dayText,
        viewMapText
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          description,
          overview,
          maxHeightDetails,
          hourText,
          dayText,
          viewMapText
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  const hourlyRate = `$${(price?.hourlyRate ? price.hourlyRate.toFixed(2) : "x")})/${text?.hourText}`
  const maxRate = price.maxRate && `$${(price?.maxRate ? price.maxRate?.toFixed(2) : "x")}/${text?.dayText}`
  const mobileTitle = `<div style="font-weight: 400; font-size: 1.125rem; line-height: 1.75rem;"><h3 style="font-weight: 400;">${title}</h3><span style="margin-right: 1rem;">${hourlyRate}</span><span>${maxRate}</span></div>`

  return (
    <>
    { isMobile ? (
        <Accordion title={mobileTitle} index={index}>
          <p css={tw`mb-4`} dangerouslySetInnerHTML={{ __html: text?.overview }} />
          <p css={tw`mb-4`} dangerouslySetInnerHTML={{ __html: text?.description }} />
          <p css={tw`mb-4`} dangerouslySetInnerHTML={{ __html: text?.maxHeightDetails }} />
          {/* <Button text={viewMapText} link={`/terminal-maps?location=${locationMapIdentifier}`} css={tw`block mb-4 mx-auto`} /> */}
        </Accordion>
    ) :
    (
      <>
        <H3 css={tw`py-4 px-6 lg:p-4 text-4xl font-extrabold mt-0`}>{text?.title}</H3>
        <p css={tw`py-4 px-6 lg:p-4`} dangerouslySetInnerHTML={{ __html: text?.overview}} />
        <div css={tw`py-4 px-6 lg:p-4 bg-altTableGray text-2xl font-extrabold`}>
          <p>{hourlyRate}</p>
          <p>{maxRate}</p>
        </div>
          <p css={tw`py-4 px-6 lg:p-4`} dangerouslySetInnerHTML={{ __html: text?.description}} />
        {/* <div css={tw`py-4 px-6 lg:p-4 bg-altTableGray text-center`}>
          <Button text={viewMapText} link={`/terminal-maps?location=${locationMapIdentifier}`} css={tw`m-auto w-full bg-altTableGray`} />
        </div> */}
        <p css={tw`py-4 px-6 bg-altTableGray lg:p-4`} dangerouslySetInnerHTML={{ __html: text?.maxHeightDetails}} />

      </>
    )}

    </>
  )
}

export default SelfParkingCard
