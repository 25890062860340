import { Interpolation, Theme } from "@emotion/react"
import React, { useState, useContext, useEffect, useRef } from "react"
import tw from "twin.macro"

const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
import AccordionArrow from "../Icons/AccordionArrow"

interface AccordionProps {
  title: any
  css?: Interpolation<Theme>
  index: number
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  css,
  index,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleHandler = () => {
    setIsExpanded(prev => !prev)
  }

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`border-b border-lightGray`, css]}>
      <button
        aria-controls={`panel${index?.toString()}`}
        id={`accordion${index?.toString()}`}
        aria-expanded={isExpanded}
        onClick={toggleHandler}
        css={[tw`relative py-4 w-full text-left`]}
      >
        <div dangerouslySetInnerHTML={{ __html: text?.title }} css={tw`mr-7`} />
        <AccordionArrow rotate={isExpanded} css={tw`absolute right-0 top-1/2 transform -translate-y-1/2`} />
      </button>
      <div
        id={`panel${index.toString()}`}
        aria-labelledby={`accordion${index.toString()}`}
        css={[
          tw`transition-all duration-500 ease-in-out overflow-hidden max-h-0`,
          isExpanded && tw`max-h-[300rem]`,
        ]}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
