import React from 'react'
import { IconProps } from './Icon.d'
import tw from 'twin.macro'

const AccordionArrow: React.FC<IconProps> = ({rotate, ...remainingProps}) => {
    return (
      <svg
          css={[tw`transition-transform`, rotate && tw`rotate-180`]}
          width="27"
          height="27"
          viewBox="0 0 27 27"
          xmlns="http://www.w3.org/2000/svg"
          {...remainingProps}
        >
          <g transform="translate(1.218 1)" fill="none" fillRule="evenodd">
            <circle fill="#385885" cx="12.5" cy="12.5" r="12.5" />
            <path
              stroke="#EAEAEA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m18.75 8.75-6.25 7.5-6.25-7.5"
            />
          </g>
        </svg>
    )
}

export default AccordionArrow
