import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import Button from "../Button/Button"

interface Props {
  headline: string
  valetContent: string
  valetParkingCta: {
    title: string
    url: string
  }
}

const ParkingValet: React.FC<Props> = ({ headline, valetContent, valetParkingCta }) => {
  const [text, setText] = useState({ headline, valetContent })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, valetContent })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, valetContent])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`mt-20 mx-auto px-4 max-w-[1024px]`]}>
      <SectionHeading textStyles={[tw`text-3xl md:text-4xl font-bold`]}>
        {text?.headline}
      </SectionHeading>
      <div
        css={[tw`[> p]:mb-4  mt-4 text-base`]}
        dangerouslySetInnerHTML={{ __html: text?.valetContent }}
      />
      {valetParkingCta?.title && (
        <Button text={valetParkingCta?.title} link={valetParkingCta?.url} styles={tw`block mx-auto mt-8`} />
      )}
    </article>
  )
}

export default ParkingValet
