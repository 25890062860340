import { Link } from "gatsby"
import React, { useState, useContext, useEffect } from "react"
import { LanguageContext } from "../../context/Language"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"

const Button: React.FC<{
  styles?: any
  link?: string
  text: string
  ariaLabel?: string
  target?: string
}> = ({ styles, link, text, ariaLabel, ...remainingProps }) => {

  const title = text ? text : "Learn More"
  const [buttonText, setButtonText] = useState(title)
  const { language } = useContext(LanguageContext)
  useEffect(() => {
    if (language === "en") {
      setButtonText(text)
    } else {
      ;(async () => {
        const translations = await TranslateClient.translate([title], language)
        if (translations) setButtonText(translations[0])
      })()
    }
  }, [language])

  /* Checks to see if "to" is an internal link (first character is a "/") */
  const isInternal = /^\/(?!\/)/.test(link)
  /* Checks to see if "to" is a pdf link (starts wiht "/static") */
  const isStaticFile = /^\/static/.test(link)

  const primaryButtonStyles=[
    tw`inline-block uppercase text-center min-w-60 w-fit mb-2 mx-auto px-8 py-3 text-grayBlack text-base font-extrabold rounded-3xl bg-teal bg-leftBottom transition transition-[background-position] duration-1000`,
    "background: linear-gradient(to right, #9ed8d5 50%, #178C88 50%); background-size: 200% 100%; outline-color: #178C88; outline-offset: 5px",
    // Hover
    tw`hover:text-white hover:bg-left-full hover:!no-underline`,
    // Focus
    tw`focus-visible:text-white focus-visible:bg-darkTeal focus-visible:bg-none `,
    // Active
    tw`active:bg-teal active:bg-none active:text-grayBlack`,
    styles,
  ]

  if(!link) {
    return (
      <button
        aria-label={ariaLabel}
        css={[...primaryButtonStyles,  styles]}
        {...remainingProps}
      >
        {buttonText}
      </button>
    )
  }

  if(!isInternal || isStaticFile) {
    return (
      <a
        aria-label={ariaLabel}
        href={link}
        css={[...primaryButtonStyles,  styles]}
        target="_blank"
        {...remainingProps}
      >
        {buttonText}
      </a>
    )
  }

  return (
      <Link
        aria-label={ariaLabel}
        to={link}
        css={[...primaryButtonStyles,  styles]}
        {...remainingProps}
      >
        {buttonText}
      </Link>
  )
}

export default Button
